import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';  // Importowanie logo

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Sprawdzamy, czy użytkownik jest już zalogowany przy ładowaniu strony
        const loggedIn = localStorage.getItem('isLoggedIn');
        const hasRedirected = localStorage.getItem('hasRedirected');
        
        // Jeśli użytkownik jest zalogowany, ale jeszcze nie został przekierowany, przekieruj go na /grafik
        if (loggedIn && !hasRedirected) {
            localStorage.setItem('hasRedirected', 'true'); // Ustawiamy flagę przekierowania
            navigate('/grafik'); // Tylko przekierowanie po pierwszym logowaniu
        }
    }, [navigate]);

    const handleLogin = () => {
        // Sprawdzenie z twardo zakodowanymi danymi
        if (
            username === 'Konto1' && password === '9UUmD7MQ.)rfeSU' || 
            username === 'Konto2' && password === '+O9Y-]~)-%gWKy+' || 
            username === 'Konto3' && password === '9pL=amje[IYUTK4' || 
            username === 'Konto4' && password === 'LHe%45by(,uV]01' || 
            username === 'Konto5' && password === '@u1nBBAq!b}X=8U' || 
            username === 'KontoRobert' && password === 'K9ky7m~PlIj=vc'
        ) {
            // Ustawiamy stan użytkownika na zalogowanego
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('hasRedirected', 'false'); // Resetujemy flagę przekierowania
            onLogin(true);
            navigate('/grafik'); // Przekierowanie po zalogowaniu
        } else {
            alert('Nieprawidłowy login lub hasło');
        }
    };

    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            justifyContent="center" 
            height="100vh" 
            sx={{ 
                padding: { xs: 2, sm: 4 }, 
                textAlign: 'center',
                maxWidth: '400px', // Maksymalna szerokość dla lepszej responsywności
                margin: '0 auto', // Wyśrodkowanie kontenera
                overflow: 'hidden' // Ukrywanie niepotrzebnego scrolla
            }} 
        >
            <Box mb={4}>
                <img src={logo} alt="Logo" style={{ maxWidth: '100%', height: 'auto' }} />
            </Box>
            <Typography variant="h4" gutterBottom>Logowanie</Typography>
            <TextField
                label="Nazwa użytkownika"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                margin="normal"
                fullWidth // Umożliwia pełną szerokość na mniejszych ekranach
            />
            <TextField
                label="Hasło"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                fullWidth // Umożliwia pełną szerokość na mniejszych ekranach
            />
            <Button 
                variant="contained" 
                color="primary" 
                onClick={handleLogin} 
                fullWidth // Umożliwia pełną szerokość na mniejszych ekranach
                sx={{ marginTop: 2 }} // Dodaje górny margines
            >
                Zaloguj
            </Button>
        </Box>
    );
};

export default Login;
