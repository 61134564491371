import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
} from '@mui/material';

const Platnosci = ({ rezerwacje = [], kontrahenci = [], flota = [] }) => {
    const [open, setOpen] = useState(false);
    const [type, setType] = useState('');
    const [form, setForm] = useState({
        ticketHistory: '',
        ticketNumber: '',
        isPaid: false,
        damageType: '',
        repairCost: '',
        invoiceStatus: '',
        paymentType: '',
        paymentMethod: '',
        kontrahent: '',
        reservation: '',
        amount: '',
        date: '',
        vehicle: ''
    });
    const [records, setRecords] = useState({
        mandaty: [],
        zniszczenia: [],
        faktura: [],
    });
    const [editMode, setEditMode] = useState(false);
    const [editId, setEditId] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState({ open: false, id: null, recordType: '' });
    const [filteredRezerwacje, setFilteredRezerwacje] = useState([]);
    const [filteredKontrahenci, setFilteredKontrahenci] = useState([]);

    const handleTypeChange = (e) => {
        setType(e.target.value);
        setForm({
            ticketHistory: '',
            ticketNumber: '',
            isPaid: false,
            damageType: '',
            repairCost: '',
            invoiceStatus: '',
            paymentType: '',
            paymentMethod: '',
            kontrahent: '',
            reservation: '',
            amount: '',
            date: '',
            vehicle: ''
        });
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setForm({ ...form, [name]: checked });
        } else {
            setForm({ ...form, [name]: value });
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (editMode) {
            setRecords((prevRecords) => {
                const updatedRecords = { ...prevRecords };
                updatedRecords[type] = updatedRecords[type].map((record) =>
                    record.id === editId ? { ...form, id: editId } : record
                );
                return updatedRecords;
            });
        } else {
            setRecords((prevRecords) => ({
                ...prevRecords,
                [type]: [...prevRecords[type], { ...form, id: Date.now() }],
            }));
        }
        setOpen(false);
        setType('');
        setForm({
            ticketHistory: '',
            ticketNumber: '',
            isPaid: false,
            damageType: '',
            repairCost: '',
            invoiceStatus: '',
            paymentType: '',
            paymentMethod: '',
            kontrahent: '',
            reservation: '',
            amount: '',
            date: '',
            vehicle: ''
        });
        setEditMode(false);
        setEditId(null);
    };

    const handleEditClick = (record, recordType) => {
        setType(recordType);
        setForm(record);
        setEditMode(true);
        setEditId(record.id);
        setOpen(true);
    };

    const handleDeleteClick = (id, recordType) => {
        setConfirmDelete({ open: true, id, recordType });
    };

    const handleConfirmDelete = () => {
        setRecords((prevRecords) => ({
            ...prevRecords,
            [confirmDelete.recordType]: prevRecords[confirmDelete.recordType].filter((record) => record.id !== confirmDelete.id),
        }));
        setConfirmDelete({ open: false, id: null, recordType: '' });
    };

    const handleCancelDelete = () => {
        setConfirmDelete({ open: false, id: null, recordType: '' });
    };

    const handleFilterRezerwacje = (e) => {
        const query = e.target.value.toLowerCase();
        setForm({ ...form, reservation: query });
        if (rezerwacje) {
            setFilteredRezerwacje(rezerwacje.filter((res) => res.toLowerCase().includes(query)));
        }
    };

    const handleFilterKontrahenci = (e) => {
        const query = e.target.value.toLowerCase();
        setForm({ ...form, kontrahent: query });
        if (kontrahenci) {
            setFilteredKontrahenci(kontrahenci.filter((k) => k.toLowerCase().includes(query)));
        }
    };

    return (
        <Box>
            <Button variant="contained" onClick={() => setOpen(true)}>Dodaj Płatność</Button>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Dodaj Płatność</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Typ Płatności</InputLabel>
                        <Select value={type} onChange={handleTypeChange}>
                            <MenuItem value="mandaty">Mandaty</MenuItem>
                            <MenuItem value="zniszczenia">Zniszczenia</MenuItem>
                            <MenuItem value="faktura">Faktura</MenuItem>
                        </Select>
                    </FormControl>

                    {/* Mandaty Fields */}
                    {type === 'mandaty' && (
                        <>
                            <TextField
                                name="ticketHistory"
                                label="Historia Mandatów"
                                value={form.ticketHistory}
                                onChange={handleInputChange}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                name="ticketNumber"
                                label="Numer Mandatu"
                                value={form.ticketNumber}
                                onChange={handleInputChange}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <TextField
                                    label="Kontrahent"
                                    name="kontrahent"
                                    value={form.kontrahent}
                                    onChange={handleFilterKontrahenci}
                                    fullWidth
                                />
                                {filteredKontrahenci.map((k, index) => (
                                    <MenuItem
                                        key={index}
                                        value={k}
                                        onClick={() => setForm({ ...form, kontrahent: k })}
                                    >
                                        {k}
                                    </MenuItem>
                                ))}
                            </FormControl>
                            <TextField
                                name="amount"
                                label="Kwota"
                                value={form.amount}
                                onChange={handleInputChange}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                name="date"
                                label="Data"
                                type="date"
                                value={form.date}
                                onChange={handleInputChange}
                                fullWidth
                                sx={{ mb: 2 }}
                                InputLabelProps={{ shrink: true }}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={form.isPaid} onChange={handleInputChange} name="isPaid" />}
                                label="Zapłacono"
                                sx={{ mb: 2 }}
                            />
                        </>
                    )}

                    {/* Zniszczenia Fields */}
                    {type === 'zniszczenia' && (
                        <>
                            <TextField
                                name="damageType"
                                label="Rodzaj Szkody"
                                value={form.damageType}
                                onChange={handleInputChange}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                name="repairCost"
                                label="Koszt Naprawy"
                                value={form.repairCost}
                                onChange={handleInputChange}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel>Pojazd</InputLabel>
                                <Select
                                    name="vehicle"
                                    value={form.vehicle}
                                    onChange={handleInputChange}
                                >
                                    {flota.map((vehicle, index) => (
                                        <MenuItem key={index} value={vehicle}>{vehicle}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </>
                    )}

                    {/* Faktura Fields */}
                    {type === 'faktura' && (
                        <>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    name="invoiceStatus"
                                    value={form.invoiceStatus}
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="paid">Opłacona</MenuItem>
                                    <MenuItem value="unpaid">Nieopłacona</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel>Metoda Płatności</InputLabel>
                                <Select
                                    name="paymentMethod"
                                    value={form.paymentMethod}
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="card">Karta</MenuItem>
                                    <MenuItem value="cash">Gotówka</MenuItem>
                                    <MenuItem value="transfer">Przelew</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <TextField
                                    label="Rezerwacja"
                                    name="reservation"
                                    value={form.reservation}
                                    onChange={handleFilterRezerwacje}
                                    fullWidth
                                />
                                {filteredRezerwacje.map((res, index) => (
                                    <MenuItem
                                        key={index}
                                        value={res}
                                        onClick={() => setForm({ ...form, reservation: res })}
                                    >
                                        {res}
                                    </MenuItem>
                                ))}
                            </FormControl>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">Anuluj</Button>
                    <Button onClick={handleFormSubmit} color="primary">Zapisz</Button>
                </DialogActions>
            </Dialog>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Typ Płatności</TableCell>
                            <TableCell>Kwota</TableCell>
                            <TableCell>Data</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {['mandaty', 'zniszczenia', 'faktura'].map((recordType) => (
                            records[recordType].map((record) => (
                                <TableRow key={record.id}>
                                    <TableCell>{recordType}</TableCell>
                                    <TableCell>{record.amount}</TableCell>
                                    <TableCell>{record.date}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleEditClick(record, recordType)}>Edytuj</Button>
                                        <Button onClick={() => handleDeleteClick(record.id, recordType)}>Usuń</Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={confirmDelete.open}
                onClose={handleCancelDelete}
            >
                <DialogTitle>Potwierdzenie usunięcia</DialogTitle>
                <DialogActions>
                    <Button onClick={handleCancelDelete}>Anuluj</Button>
                    <Button onClick={handleConfirmDelete} color="primary">Usuń</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Platnosci;
