import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Grid,
    FormHelperText
} from '@mui/material';
import { useOperationPopup } from './OperationConfirmationPopup'; // Importuj hook
import './styles.css';
import { useConfirmDelete } from './ConfirmDelete';

const OsobaPrywatna = ({ osoby, onAdd, onDelete, onEdit }) => {
    const [form, setForm] = useState({
        imie: '', nazwisko: '', pesel: '', adres: '', kod_pocztowy: '',
        email: '', telefon: '', nr_dowodu: '', prawo_jazdy: '', data_urodzenia: ''
    });
    const [filters, setFilters] = useState({
        imie: '', nazwisko: '', pesel: '', adres: '', kod_pocztowy: '',
        email: '', telefon: '', nr_dowodu: '', prawo_jazdy: '', data_urodzenia: ''
    });
    const [open, setOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editId, setEditId] = useState(null);
    const [fetchedOsoby, setFetchedOsoby] = useState([]);
    const [filtersVisible, setFiltersVisible] = useState(false); // Kontrola widoczności filtrów
    const [emailError, setEmailError] = useState(""); // Walidacja e-maila
    const { confirmDelete, ConfirmationDialog } = useConfirmDelete();
    const { showPopup, Popup } = useOperationPopup(); // Hook do obsługi popupu

    // Pobieranie danych z API po załadowaniu komponentu
    useEffect(() => {
        fetch('https://autosolgasystem.pl/django-app/dbview/osoba_disp')
            .then((response) => response.json())
            .then((data) => setFetchedOsoby(data))
            .catch((error) => console.error('Błąd pobierania danych:', error));
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    // Walidacja e-maila
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        // Walidacja e-maila
        if (!validateEmail(form.email)) {
            setEmailError('Podaj prawidłowy adres e-mail');
            return;
        } else {
            setEmailError('');
        }

        const payload = { ...form, id: Date.now() };

        if (isEditMode) {
            // Edytowanie osoby prywatnej (PUT request)
            fetch(`https://autosolgasystem.pl/django-app/dbview/update_osoba/${editId}/`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Błąd serwera');
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log("Osoba zaktualizowana:", data);
                    setFetchedOsoby((prevOsoby) => prevOsoby.map((osoba) =>
                        osoba.id === editId ? { ...osoba, ...data } : osoba
                    ));
                    setForm({
                        imie: '', nazwisko: '', pesel: '', adres: '', kod_pocztowy: '',
                        email: '', telefon: '', nr_dowodu: '', prawo_jazdy: '', data_urodzenia: ''
                    });
                    setOpen(false);
                    setIsEditMode(false);
                    setEditId(null);
                })
                .catch((error) => {
                    console.error("Błąd podczas edytowania osoby:", error);
                    alert("Nie udało się zaktualizować osoby. Sprawdź szczegóły w konsoli.");
                });
        } else {
            // Tworzenie nowej osoby prywatnej (POST request)
            fetch('https://autosolgasystem.pl/django-app/dbview/create_osoba', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    setFetchedOsoby((prev) => [...prev, data]);
                    showPopup('Rekord został pomyślnie dodany!');
                    handleCloseForm();
                });
        }
    };

    const handleDeleteClick = async (id) => {
        const confirmed = await confirmDelete('Czy na pewno chcesz usunąć ten rekord?');
        if (confirmed) {
            // Usuwanie osoby prywatnej (DELETE request)
            fetch(`https://autosolgasystem.pl/django-app/dbview/delete_osoba/${id}/`, {
                method: 'DELETE',
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Błąd serwera');
                    }
                    return response.json();
                })
                .then(() => {
                    console.log("Osoba usunięta pomyślnie");
                    showPopup('Rekord został pomyślnie usunięty!');
                    setFetchedOsoby(fetchedOsoby.filter((osoba) => osoba.id !== id));
                })
                .catch((error) => {
                    console.error("Błąd podczas usuwania osoby:", error);
                    alert("Nie udało się usunąć osoby. Sprawdź szczegóły w konsoli.");
                });
        }
    };

    const handleEditClick = (osoba) => {
        setForm(osoba);
        setEditId(osoba.id);
        setIsEditMode(true);
        setOpen(true);
    };

    const handleCloseForm = () => {
        setOpen(false);
        setIsEditMode(false);
        setForm({
            imie: '', nazwisko: '', pesel: '', adres: '', kod_pocztowy: '',
            email: '', telefon: '', nr_dowodu: '', prawo_jazdy: '', data_urodzenia: ''
        });
    };

    const filteredOsoby = fetchedOsoby.filter((osoba) => {
        return Object.keys(filters).every((key) =>
            osoba[key]?.toLowerCase().includes(filters[key].toLowerCase())
        );
    });

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>Osoby Prywatne</Typography>
            <Box className="button-group">
                <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                    Dodaj Osobę
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setFiltersVisible(!filtersVisible)}
                >
                    Filtruj
                </Button>
            </Box>
            <Popup />

            {/* Filtry */}
            {filtersVisible && (
                <Grid container spacing={2} sx={{ marginY: 2 }}>
                    {Object.keys(filters).map((field) => (
                        <Grid item xs={12} sm={3} key={field}>
                            <TextField
                                name={field}
                                label={field.replace('_', ' ').toUpperCase()}
                                value={filters[field]}
                                onChange={handleFilterChange}
                                fullWidth
                            />
                        </Grid>
                    ))}
                </Grid>
            )}

            {/* Tabela */}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {['Imię', 'Nazwisko', 'PESEL', 'Adres', 'Kod Pocztowy', 'Email', 'Telefon', 'Nr Dowodu', 'Prawo Jazdy', 'Data Urodzenia', 'Akcje'].map((header) => (
                                <TableCell key={header}>{header}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredOsoby.map((osoba) => (
                            <TableRow key={osoba.id}>
                                <TableCell>{osoba.imie}</TableCell>
                                <TableCell>{osoba.nazwisko}</TableCell>
                                <TableCell>{osoba.pesel}</TableCell>
                                <TableCell>{osoba.adres}</TableCell>
                                <TableCell>{osoba.kod_pocztowy}</TableCell>
                                <TableCell>{osoba.email}</TableCell>
                                <TableCell>{osoba.telefon}</TableCell>
                                <TableCell>{osoba.nr_dowodu}</TableCell>
                                <TableCell>{osoba.prawo_jazdy}</TableCell>
                                <TableCell>{osoba.data_urodzenia}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEditClick(osoba)}>Edytuj</Button>
                                    <Button onClick={() => handleDeleteClick(osoba.id)}>Usuń</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Formularz */}
            <Dialog open={open} onClose={handleCloseForm}>
                <DialogTitle>{isEditMode ? 'Edytuj Osobę' : 'Dodaj Osobę'}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {Object.keys(form).map((field) => (
                            <Grid item xs={12} sm={6} key={field}>
                                {field === "data_urodzenia" ? (
                                    <TextField
                                        name={field}
                                        label="Data Urodzenia"
                                        type="date"
                                        value={form[field]}
                                        onChange={handleInputChange}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                ) : (
                                    <TextField
                                        name={field}
                                        label={field.replace('_', ' ').toUpperCase()}
                                        value={form[field]}
                                        onChange={handleInputChange}
                                        fullWidth
                                        error={field === "email" && emailError !== ""}
                                        helperText={field === "email" ? emailError : ""}
                                    />
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseForm} color="primary">Anuluj</Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        {isEditMode ? 'Zapisz' : 'Dodaj'}
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmationDialog />
        </Box>
    );
};

export default OsobaPrywatna;
