import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

const OperationConfirmationPopup = ({ open, message, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Potwierdzenie</DialogTitle>
            <DialogContent>
                <p>{message}</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Zamknij
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const useOperationPopup = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("");

    const showPopup = (msg) => {
        setMessage(msg);
        setIsOpen(true);
    };

    const hidePopup = () => {
        setIsOpen(false);
        setMessage("");
    };

    const Popup = () => (
        <OperationConfirmationPopup
            open={isOpen}
            message={message}
            onClose={hidePopup}
        />
    );

    return { showPopup, Popup };
};
