import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const SprzedanePojazdy = ({ sprzedane }) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Model</TableCell>
                        <TableCell>Marka</TableCell>
                        <TableCell>VIN</TableCell>
                        <TableCell>Data Sprzedaży</TableCell>
                        <TableCell>Cena Sprzedaży</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sprzedane.map((pojazd) => (
                        <TableRow key={pojazd.id}>
                            <TableCell>{pojazd.model}</TableCell>
                            <TableCell>{pojazd.marka}</TableCell>
                            <TableCell>{pojazd.vin}</TableCell>
                            <TableCell>{new Date().toLocaleDateString()}</TableCell>
                            <TableCell>{pojazd.sellPrice} zł</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SprzedanePojazdy;
