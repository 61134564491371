import React, { useState, useEffect } from 'react';
import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import pl from 'date-fns/locale/pl';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const locales = { pl };
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

const Grafik = () => {
    const [rezerwacje, setRezerwacje] = useState([]);
    const [flota, setFlota] = useState([]);
    const [firmy, setFirmy] = useState([]);
    const [osobyPrywatne, setOsobyPrywatne] = useState([]);
    const [selectedRezerwacja, setSelectedRezerwacja] = useState(null);
    const [editedData, setEditedData] = useState({});
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const rezerwacjeRes = await fetch('https://autosolgasystem.pl/django-app/dbview/rezerwacja_disp');
                const flotaRes = await fetch('https://autosolgasystem.pl/django-app/dbview/');
                const firmyRes = await fetch('https://autosolgasystem.pl/django-app/dbview/firma_disp');
                const osobyRes = await fetch('https://autosolgasystem.pl/django-app/dbview/osoba_disp');

                const [rezerwacjeData, flotaData, firmyData, osobyData] = await Promise.all([
                    rezerwacjeRes.json(),
                    flotaRes.json(),
                    firmyRes.json(),
                    osobyRes.json(),
                ]);

                setRezerwacje(rezerwacjeData);
                setFlota(flotaData);
                setFirmy(firmyData);
                setOsobyPrywatne(osobyData);
            } catch (error) {
                console.error('Błąd podczas pobierania danych:', error);
            }
        };

        fetchData();
    }, []);

    const events = rezerwacje.map((rezerwacja) => {
        const auto = flota.find((a) => a.id === rezerwacja.auto_id);
        const firma = firmy.find((f) => f.id === rezerwacja.firma_id);
        const osoba = osobyPrywatne.find((o) => o.id === rezerwacja.osoba_prywatna_id);

        return {
            id: rezerwacja.id,
            title: `Auto: ${auto ? `${auto.marka} ${auto.model}` : 'Nieznane'} | ${
                firma ? firma.nazwa_firmy : osoba ? `${osoba.imie} ${osoba.nazwisko}` : 'Brak danych'
            }`,
            start: new Date(rezerwacja.odKiedy),
            end: new Date(rezerwacja.doKiedy),
            auto: auto ? `${auto.marka} ${auto.model}` : '',
            klient: firma ? firma.nazwa_firmy : osoba ? `${osoba.imie} ${osoba.nazwisko}` : '',
            odKiedy: rezerwacja.odKiedy,
            doKiedy: rezerwacja.doKiedy,
        };
    });

    const handleEventClick = (event) => {
        setSelectedRezerwacja(event);
        setEditedData({
            auto: event.auto,
            klient: event.klient,
            odKiedy: event.odKiedy,
            doKiedy: event.doKiedy,
        });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedRezerwacja(null);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSave = async () => {
        try {
            const response = await fetch(
                `https://autosolgasystem.pl/django-app/dbview/update_rezerwacja/${selectedRezerwacja.id}/`,
                {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        odKiedy: editedData.odKiedy,
                        doKiedy: editedData.doKiedy,
                    }),
                }
            );

            if (response.ok) {
                console.log('Rezerwacja zaktualizowana pomyślnie.');
                setRezerwacje((prev) =>
                    prev.map((rez) =>
                        rez.id === selectedRezerwacja.id
                            ? { ...rez, odKiedy: editedData.odKiedy, doKiedy: editedData.doKiedy }
                            : rez
                    )
                );
            } else {
                console.error('Błąd podczas aktualizacji rezerwacji.');
            }
        } catch (error) {
            console.error('Wystąpił błąd podczas aktualizacji:', error);
        }
        handleClose();
    };

    return (
        <Box className="main-content" style={{ margin: '20px' }}>
            <Typography variant="h4" gutterBottom align="center">
                Grafik Rezerwacji
            </Typography>
            <Box style={{ height: '80vh' }}>
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: '100%' }}
                    views={['month', 'week', 'day']}
                    onSelectEvent={handleEventClick}
                    locale="pl"
                    messages={{
                        next: 'Następny',
                        previous: 'Poprzedni',
                        today: 'Dziś',
                        month: 'Miesiąc',
                        week: 'Tydzień',
                        day: 'Dzień',
                        date: 'Data',
                        time: 'Czas',
                        event: 'Rezerwacja',
                        showMore: (total) => `+${total} więcej`,
                        weekday: ['Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob', 'Nd'],
                    }}
                />
            </Box>

            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>Edycja Rezerwacji</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="Auto"
                        fullWidth
                        value={editedData.auto}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        margin="dense"
                        label="Klient"
                        fullWidth
                        value={editedData.klient}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        margin="dense"
                        label="Od Kiedy"
                        name="odKiedy"
                        type="date"
                        fullWidth
                        value={editedData.odKiedy}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        label="Do Kiedy"
                        name="doKiedy"
                        type="date"
                        fullWidth
                        value={editedData.doKiedy}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleSave} color="primary" variant="contained">
                        Zapisz
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Grafik;
