import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Grid,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Collapse,
} from '@mui/material';

const Zniszczenia = () => {
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState({
        id: '',
        rodzaj_szkody: '',
        koszt_naprawy: '',
        pojazd_id: '',  // Correct field for pojazd_id
        data: '',
    });
    const [zniszczenia, setZniszczenia] = useState([]);
    const [flota, setFlota] = useState([]); // State for pojazd data
    const [editMode, setEditMode] = useState(false);
    const [editId, setEditId] = useState(null);
    const [openFilters, setOpenFilters] = useState(false);  // State for managing filter visibility
    const [filters, setFilters] = useState({
        rodzaj_szkody: '',
        pojazd: '',
        data: '',
    });
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false); // State for confirm delete dialog
    const [selectedZniszczenieId, setSelectedZniszczenieId] = useState(null); // Store the id of the zniszczenie to delete

    useEffect(() => {
        // Fetching Zniszczenia from Django endpoint
        fetch('https://autosolgasystem.pl/django-app/dbview/zniszczenie_disp')
            .then((response) => response.json())
            .then((data) => setZniszczenia(data))
            .catch((error) => console.error('Error fetching zniszczenia:', error));

        // Fetching Flota (pojazd) data
        fetch('https://autosolgasystem.pl/django-app/dbview/')
            .then((response) => response.json())
            .then((data) => setFlota(data))
            .catch((error) => console.error('Error fetching pojazds (flota):', error));
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const payload = {
            rodzaj_szkody: form.rodzaj_szkody,
            koszt_naprawy: form.koszt_naprawy,
            pojazd_id: form.pojazd_id,  // Corrected field to pojazd_id
            data: form.data,
        };

        if (editMode) {
            // Update the Zniszczenie record
            fetch(`https://autosolgasystem.pl/django-app/dbview/update_zniszczenie/${form.id}/`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    setZniszczenia((prevZniszczenia) =>
                        prevZniszczenia.map((z) => (z.id === form.id ? { ...z, ...data } : z))
                    );
                    resetForm();
                })
                .catch((error) => console.error('Error updating zniszczenie:', error));
        } else {
            // Create a new Zniszczenie record
            fetch('https://autosolgasystem.pl/django-app/dbview/create_zniszczenie', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    setZniszczenia((prevZniszczenia) => [...prevZniszczenia, data]);
                    resetForm();
                })
                .catch((error) => console.error('Error creating zniszczenie:', error));
        }
    };

    const resetForm = () => {
        setForm({
            id: '',
            rodzaj_szkody: '',
            koszt_naprawy: '',
            pojazd_id: '',  // Reset pojazd_id as well
            data: '',
        });
        setEditMode(false);
        setEditId(null);
        setOpen(false);
    };

    const handleDeleteClick = (id) => {
        setSelectedZniszczenieId(id); // Set the selected Zniszczenie ID to delete
        setOpenConfirmDialog(true); // Open the confirmation dialog
    };

    const handleConfirmDelete = () => {
        if (selectedZniszczenieId !== null) {
            fetch(`https://autosolgasystem.pl/django-app/dbview/delete_zniszczenie/${selectedZniszczenieId}/`, {
                method: 'DELETE',
            })
                .then(() => {
                    setZniszczenia((prevZniszczenia) =>
                        prevZniszczenia.filter((z) => z.id !== selectedZniszczenieId)
                    );
                    setOpenConfirmDialog(false); // Close the confirmation dialog
                })
                .catch((error) => console.error('Error deleting zniszczenie:', error));
        }
    };

    const handleCancelDelete = () => {
        setOpenConfirmDialog(false); // Close the confirmation dialog without deleting
    };

    const handleEditClick = (zniszczenie) => {
        // Wartość 'pojazd_id' jest poprawnie przypisana w formie
        setForm({
            ...zniszczenie,
            pojazd_id: zniszczenie.pojazd_id || '',  // Upewnij się, że pojazd_id jest ustawione poprawnie
        });
        setEditMode(true);
        setEditId(zniszczenie.id);
        setOpen(true);
    };


    // Apply filters to Zniszczenia
    const filteredZniszczenia = zniszczenia.filter((zniszczenie) => {
        const matchesRodzajSzkody = filters.rodzaj_szkody === '' || zniszczenie.rodzaj_szkody.toLowerCase().includes(filters.rodzaj_szkody.toLowerCase());
        const matchesPojazd = filters.pojazd === '' || zniszczenie.pojazd === filters.pojazd;
        const matchesData = filters.data === '' || zniszczenie.data.includes(filters.data);
        return matchesRodzajSzkody && matchesPojazd && matchesData;
    });

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Zniszczenia
            </Typography>
            <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                Dodaj Zniszczenie
            </Button>
            <Button
                variant="outlined"
                color="secondary"
                onClick={() => setOpenFilters(!openFilters)}  // Toggle filter visibility
            >
                Filtruj
            </Button>

            {/* Filters */}
            <Collapse in={openFilters} timeout="auto" unmountOnExit>
                <Box sx={{ marginTop: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Rodzaj Szkody"
                                name="rodzaj_szkody"
                                value={filters.rodzaj_szkody}
                                onChange={handleFilterChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel>Pojazd</InputLabel>
                                <Select
                                    name="pojazd"
                                    value={filters.pojazd}
                                    onChange={handleFilterChange}
                                    fullWidth
                                >
                                    <MenuItem value="">Wszystkie</MenuItem>
                                    {flota.map((pojazd, index) => (
                                        <MenuItem key={index} value={pojazd.id}>{pojazd.marka} {pojazd.model}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="data"
                                label="Data"
                                type="date"
                                value={filters.data}
                                onChange={handleFilterChange}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Collapse>

            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Rodzaj Szkody</TableCell>
                            <TableCell>Koszt Naprawy</TableCell>
                            <TableCell>Pojazd</TableCell>
                            <TableCell>Data</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredZniszczenia.map((zniszczenie) => (
                            <TableRow key={zniszczenie.id}>
                                <TableCell>{zniszczenie.rodzaj_szkody}</TableCell>
                                <TableCell>{zniszczenie.koszt_naprawy}</TableCell>
                                <TableCell>
                                    {
                                        flota.find((auto) => auto.id === zniszczenie.pojazd_id) // Poprawione odniesienie do pojazd_id
                                            ? flota.find((auto) => auto.id === zniszczenie.pojazd_id).marka + " " + flota.find((auto) => auto.id === zniszczenie.pojazd_id).model
                                            : 'Brak'
                                    }
                                </TableCell>
                                <TableCell>{zniszczenie.data}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEditClick(zniszczenie)}>Edytuj</Button>
                                    <Button onClick={() => handleDeleteClick(zniszczenie.id)}>Usuń</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>{editMode ? 'Edytuj Zniszczenie' : 'Dodaj Zniszczenie'}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="rodzaj_szkody"
                                    label="Rodzaj Szkody"
                                    value={form.rodzaj_szkody}
                                    onChange={handleInputChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="koszt_naprawy"
                                    label="Koszt Naprawy"
                                    value={form.koszt_naprawy}
                                    onChange={handleInputChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel>Pojazd</InputLabel>
                                    <Select
                                        name="pojazd_id"
                                        value={form.pojazd_id}
                                        onChange={handleInputChange}
                                        fullWidth
                                        required
                                    >
                                        {flota.map((pojazd, index) => (
                                            <MenuItem key={index} value={pojazd.id}>
                                                {pojazd.marka} {pojazd.model}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="data"
                                    label="Data"
                                    type="date"
                                    value={form.data}
                                    onChange={handleInputChange}
                                    fullWidth
                                    required
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        Zapisz
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confirmation Dialog */}
            <Dialog open={openConfirmDialog} onClose={handleCancelDelete}>
                <DialogTitle>Potwierdzenie usunięcia</DialogTitle>
                <DialogContent>
                    <Typography>Jesteś pewny, że chcesz usunąć to zniszczenie?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary">
                        Usuń
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Zniszczenia;
