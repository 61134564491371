import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './components/theme'; // Ścieżka do pliku motywu
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Box, CssBaseline, Toolbar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from './components/Sidebar';
import Kontrahent from './components/Kontrahent';
import Flota from './components/Flota';
import Rezerwacje from './components/Rezerwacje';
import Grafik from './components/Grafik';
import Magazyn from './components/Magazyn';
import WydanieSamochodu from './components/WydanieSamochodu';
import UmowaNajmuPriv from './components/UmowaNajmuPriv';
import UmowaNajmuFirma from './components/UmowaNajmuFirma';
import Platnosci from './components/Platnosci';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import Ustawienia from './components/Ustawienia';
import Firma from './components/Firma';
import OsobaPrywatna from './components/OsobaPrywatna';
import HistoriaSerwisowa from './components/HistoriaSerwisowa';
import ZezlomowanePojazdy from './components/ZezlomowanePojazdy';
import SprzedanePojazdy from './components/SprzedanePojazdy';
import UsunietePojazdy from './components/UsunietePojazdy';
import Mandaty from './components/Mandaty';
import Faktury from './components/Faktury';
import Zniszczenia from './components/Zniszczenia';
import ArchiwalneUmowyPriv from './components/ArchiwalneUmowyPriv';
import ArchiwalneUmowyFirmowe from './components/ArchiwalneUmowyFirmowe';

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isOpen, setIsOpen] = useState(true);

    // Ładowanie stanu logowania z localStorage
    useEffect(() => {
        const loggedInStatus = localStorage.getItem('isLoggedIn');
        if (loggedInStatus === 'true') {
            setIsAuthenticated(true);
        }
    }, []);

    const handleLogin = (status) => {
        setIsAuthenticated(status);
        if (status) {
            // Zapisujemy stan logowania w localStorage
            localStorage.setItem('isLoggedIn', 'true');
        }
    };

    const handleLogout = () => {
        setIsAuthenticated(false);
        localStorage.setItem('isLoggedIn', 'false');
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    {isAuthenticated && (
                        <Sidebar handleLogout={handleLogout} isOpen={isOpen} toggleSidebar={toggleSidebar} />
                    )}
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            p: 3,
                            transition: 'margin 0.3s ease-in-out',
                            marginLeft: isAuthenticated ? (isOpen ? '240px' : '0') : '0',
                        }}
                    >
                        <Toolbar>
                            {isAuthenticated && (
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={toggleSidebar}
                                    sx={{ mr: 2 }}
                                >
                                    <MenuIcon />
                                </IconButton>
                            )}
                        </Toolbar>
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    isAuthenticated ? (
                                        <Navigate to="/" /> // Utrzymuje użytkownika na tej samej stronie po zalogowaniu
                                    ) : (
                                        <Navigate to="/login" />
                                    )
                                }
                            />
                            <Route
                                path="/login"
                                element={isAuthenticated ? <Navigate to="/" /> : <Login onLogin={handleLogin} />}
                            />
                            <Route
                                path="/kontrahent"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <Kontrahent />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/flota"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <Flota />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/flota/historia-serwisowa"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <HistoriaSerwisowa />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/rezerwacje"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <Rezerwacje />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/grafik"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <Grafik />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/magazyn"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <Magazyn />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/generator-dokumentu/wydanie-samochodu"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <WydanieSamochodu />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/generator-dokumentu/umowa-najmu-prywatna"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <UmowaNajmuPriv />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/generator-dokumentu/umowa-najmu-firma"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <UmowaNajmuFirma />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/platnosci"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <Platnosci />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/platnosci/mandaty"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <Mandaty />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/platnosci/zniszczenia"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <Zniszczenia />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/platnosci/faktury"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <Faktury />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/kontrahent/firma"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <Firma />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/kontrahent/osoba-prywatna"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <OsobaPrywatna />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/historia/zezlomowane-pojazdy"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <ZezlomowanePojazdy />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/historia/sprzedane-pojazdy"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <SprzedanePojazdy />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/historia/usuniete-pojazdy"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <UsunietePojazdy />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/historia/archiwalne-umowy-priv"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <ArchiwalneUmowyPriv />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/historia/archiwalne-umowy-firma"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <ArchiwalneUmowyFirmowe />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/ustawienia"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <Ustawienia />
                                    </PrivateRoute>
                                }
                            />
                        </Routes>
                    </Box>
                </Box>
            </Router>
        </ThemeProvider>
    );
};

export default App;
