import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, Box, Button, IconButton, Collapse, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import logo from '../assets/logo.png'; // Importowanie logo

const Sidebar = ({ handleLogout, isOpen, toggleSidebar }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Wykrywanie urządzeń mobilnych
    const [openKontrahent, setOpenKontrahent] = useState(false); // Stan dla rozwijania listy Kontrahent
    const [openWydaniesamochodu, setOpenWydaniesamochodu] = useState(false);
    const [openPlatnosci, setOpenPlatnosci] = useState(false);
    const [openHistoria, setOpenHistoria] = useState(false);

    const handleKontrahentClick = () => {
        setOpenKontrahent(!openKontrahent); // Przełącznik rozwijania listy "Kontrahent"
    };

    const handleWydanieSamochoduClick = () => {
        setOpenWydaniesamochodu(!openWydaniesamochodu);
    };

    const handlePlatnosciClick = () => {
        setOpenPlatnosci(!openPlatnosci);
    };

    const handleHistoriaClick = () => {
        setOpenHistoria(!openHistoria);
    };

    return (
        <Drawer
            variant={isMobile ? 'temporary' : 'persistent'} // 'persistent' na desktopie
            open={isOpen}
            onClose={toggleSidebar}
            sx={{
                '& .MuiDrawer-paper': {
                    width: 240,
                    boxSizing: 'border-box',
                    height: '100vh', // Pełna wysokość
                    overflowY: 'auto', // Przewijanie w pionie
                    transition: 'width 0.3s ease-in-out',
                },
            }}
            ModalProps={{
                keepMounted: true, // Utrzymywanie sidebara zamontowanego na mobilkach
            }}
        >
            {/* Sekcja logo */}
            <Box sx={{ p: 2, textAlign: 'center' }}>
                <img src={logo} alt="Logo" style={{ maxWidth: '100%' }} />
            </Box>

            {/* Sekcja z linkami */}
            <Box sx={{ flexGrow: 1 }}>
                <List>
                    {/* Kontrahent z podkategoriami */}
                    <ListItem button onClick={handleKontrahentClick}>
                        <ListItemText primary="Kontrahent" />
                        <IconButton edge="end">
                            <ExpandMoreIcon />
                        </IconButton>
                    </ListItem>
                    <Collapse in={openKontrahent} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button component={Link} to="/kontrahent/osoba-prywatna" sx={{ pl: 4 }}>
                                <ListItemText primary="Osoba Fizyczna" />
                            </ListItem>
                            <ListItem button component={Link} to="/kontrahent/firma" sx={{ pl: 4 }}>
                                <ListItemText primary="Firma" />
                            </ListItem>
                        </List>
                    </Collapse>

                    {/* Pozostałe linki */}
                    <ListItem button component={Link} to="/flota">
                        <ListItemText primary="Flota" />
                    </ListItem>
                    <ListItem button component={Link} to="/flota/historia-serwisowa">
                        <ListItemText primary="Historia Serwisowa" />
                    </ListItem>
                    <ListItem button component={Link} to="/rezerwacje">
                        <ListItemText primary="Rezerwacje" />
                    </ListItem>
                    <ListItem button component={Link} to="/grafik">
                        <ListItemText primary="Grafik" />
                    </ListItem>
                  {/*  <ListItem button component={Link} to="/platnosci">
                        <ListItemText primary="Płatności" />
                    </ListItem>*/}


                    <ListItem button onClick={handlePlatnosciClick}>
                            <ListItemText primary="Płatności" />
                            <IconButton edge="end">
                                <ExpandMoreIcon />
                            </IconButton>
                    </ListItem>
                    <Collapse in={openPlatnosci} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button component={Link} to="/platnosci/mandaty" sx={{ pl: 4 }}>
                                <ListItemText primary="Mandaty" />
                            </ListItem>
                            <ListItem button component={Link} to="/platnosci/faktury" sx={{ pl: 4 }}>
                                <ListItemText primary="Faktury" />
                            </ListItem>
                            <ListItem button component={Link} to="/platnosci/zniszczenia" sx={{ pl: 4 }}>
                                <ListItemText primary="Zniszczenia" />
                            </ListItem>
                        </List>
                    </Collapse>






                    <ListItem button onClick={handleWydanieSamochoduClick}>
                            <ListItemText primary="Generator dokumentów" />
                            <IconButton edge="end">
                                <ExpandMoreIcon />
                            </IconButton>
                    </ListItem>
                    <Collapse in={openWydaniesamochodu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button component={Link} to="/generator-dokumentu/wydanie-samochodu" sx={{ pl: 4 }}>
                                <ListItemText primary="Protokół odbioru" />
                            </ListItem>
                            <ListItem button component={Link} to="/generator-dokumentu/umowa-najmu-prywatna" sx={{ pl: 4 }}>
                                <ListItemText primary="Umowa najmu (prywatny)" />
                            </ListItem>
                            <ListItem button component={Link} to="/generator-dokumentu/umowa-najmu-firma" sx={{ pl: 4 }}>
                                <ListItemText primary="Umowa najmu (firmowy)" />
                            </ListItem>
                        </List>
                    </Collapse>


                    <ListItem button component={Link} to="/magazyn">
                        <ListItemText primary="Magazyn" />
                    </ListItem>

                    <ListItem button onClick={handleHistoriaClick}>
                            <ListItemText primary="Historia" />
                            <IconButton edge="end">
                                <ExpandMoreIcon />
                            </IconButton>
                    </ListItem>
                    <Collapse in={openHistoria} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button component={Link} to="/historia/zezlomowane-pojazdy" sx={{ pl: 4 }}>
                                <ListItemText primary="Zezłomowane Pojazdy" />
                            </ListItem>
                            <ListItem button component={Link} to="/historia/sprzedane-pojazdy" sx={{ pl: 4 }}>
                                <ListItemText primary="Sprzedane Pojazdy" />
                            </ListItem>
                            <ListItem button component={Link} to="/historia/usuniete-pojazdy" sx={{ pl: 4 }}>
                                <ListItemText primary="Usunięte Pojazdy" />
                            </ListItem>
                            <ListItem button component={Link} to="/historia/archiwalne-umowy-priv" sx={{ pl: 4 }}>
                                <ListItemText primary="Zarchiwizowane umowy prywatne" />
                            </ListItem>
                            <ListItem button component={Link} to="/historia/archiwalne-umowy-firma" sx={{ pl: 4 }}>
                                <ListItemText primary="Zarchiwizowane umowy firmowe" />
                            </ListItem>
                        </List>
                    </Collapse>


                    {/*<ListItem button component={Link} to="/kontrahent">
                        <ListItemText primary="Inne" />
                    </ListItem>*/}
                    {/*<ListItem button component={Link} to="/ustawienia">
                        <ListItemText primary="Ustawienia" />
                    </ListItem>*/}
                </List>
            </Box>

            {/* Sekcja przycisku wylogowania */}
            <Box sx={{ p: 2 }}>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<LogoutIcon />}
                    onClick={handleLogout}
                    fullWidth
                >
                    Wyloguj
                </Button>
            </Box>
        </Drawer>
    );
};

export default Sidebar;
