import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    FormControl,
    MenuItem,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import './styles.css';
import { useConfirmDelete } from './ConfirmDelete';

const Rezerwacje = ({ onAdd, onDelete, onUpdate }) => {
    const [form, setForm] = useState({
        id: '',
        odKiedy: '',
        doKiedy: '',
        autoId: '',
        firmaId: '',
        osobaPrywatnaId: '',
    });

    const [open, setOpen] = useState(false);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [filters, setFilters] = useState({
        autoId: '',
        nazwa_firmy: '',
        nazwisko: '',
    });

    const [rezerwacje, setRezerwacje] = useState([]);
    const [flota, setFlota] = useState([]);
    const [firmy, setFirmy] = useState([]);
    const [osobyPrywatne, setOsobyPrywatne] = useState([]);

    const { confirmDelete, ConfirmationDialog } = useConfirmDelete();

    // Fetch all related data when the component mounts
    useEffect(() => {
        // Fetch rezerwacje data
        fetch('https://autosolgasystem.pl/django-app/dbview/rezerwacja_disp')
            .then((response) => response.json())
            .then((data) => setRezerwacje(data))
            .catch((error) => console.error('Błąd pobierania rezerwacji:', error));

        // Fetch flota (cars) data
        fetch('https://autosolgasystem.pl/django-app/dbview/')
            .then((response) => response.json())
            .then((data) => setFlota(data))
            .catch((error) => console.error('Błąd pobierania floty:', error));

        // Fetch firmy data
        fetch('https://autosolgasystem.pl/django-app/dbview/firma_disp')
            .then((response) => response.json())
            .then((data) => setFirmy(data))
            .catch((error) => console.error('Błąd pobierania firm:', error));

        // Fetch osoby prywatne data
        fetch('https://autosolgasystem.pl/django-app/dbview/osoba_disp')
            .then((response) => response.json())
            .then((data) => setOsobyPrywatne(data))
            .catch((error) => console.error('Błąd pobierania osób prywatnych:', error));
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });
    };


    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
    
        // Ensure that exactly one of firmaId or osobaPrywatnaId is selected
        if (!form.firmaId && !form.osobaPrywatnaId) {
            alert('Wybierz firmę lub osobę prywatną.');
            return;
        }
    
        if (form.firmaId && form.osobaPrywatnaId) {
            alert('Możesz wybrać tylko jedną opcję: firmę lub osobę prywatną.');
            return;
        }
    
        // Prepare the payload without the `id` field if it's a new record
        const payload = {
            odKiedy: form.odKiedy,
            doKiedy: form.doKiedy,
            autoId: form.autoId,
            firmaId: form.firmaId || undefined, // Use undefined if not selected
            osobaPrywatnaId: form.osobaPrywatnaId || undefined, // Use undefined if not selected
        };
    
        if (form.id) {
            // Update existing rezerwacja
            fetch(`https://autosolgasystem.pl/django-app/dbview/update_rezerwacja/${form.id}/`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log('Rezerwacja zaktualizowana:', data);
                    setRezerwacje((prevRezerwacje) =>
                        prevRezerwacje.map((rezerwacja) =>
                            rezerwacja.id === form.id ? { ...rezerwacja, ...data } : rezerwacja
                        )
                    );
                    setForm({ id: '', odKiedy: '', doKiedy: '', autoId: '', firmaId: '', osobaPrywatnaId: '' });
                    setOpen(false);
                })
                .catch((error) => console.error('Błąd podczas aktualizacji rezerwacji:', error));
        } else {
            // Create new rezerwacja without including `id`
            fetch('https://autosolgasystem.pl/django-app/dbview/create_rezerwacja', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    setRezerwacje((prevRezerwacje) => [...prevRezerwacje, data]);
                    setForm({ id: '', odKiedy: '', doKiedy: '', autoId: '', firmaId: '', osobaPrywatnaId: '' });
                    setOpen(false);
                })
                .catch((error) => console.error('Błąd podczas tworzenia rezerwacji:', error));
        }
    };
    
    


    const handleDeleteClick = async (id) => {
        const confirmed = await confirmDelete('Czy na pewno chcesz usunąć tę rezerwację?');
        if (confirmed) {
            // Delete rezerwacja
            fetch(`https://autosolgasystem.pl/django-app/dbview/delete_rezerwacja/${id}/`, {
                method: 'DELETE',
            })
                .then((response) => response.json())
                .then(() => {
                    setRezerwacje((prevRezerwacje) => prevRezerwacje.filter((rezerwacja) => rezerwacja.id !== id));
                })
                .catch((error) => console.error('Błąd podczas usuwania rezerwacji:', error));
        }
    };

    const handleEditClick = (rezerwacja) => {
        setForm(rezerwacja);
        setOpen(true);
    };

    // Filter rezerwacje based on the filters
    const filteredRezerwacje = rezerwacje.filter((rezerwacja) => {
        const matchesAuto = filters.autoId === '' || rezerwacja.autoId === filters.autoId;
        const matchesFirma = filters.nazwa_firmy === '' || rezerwacja.firmaId === filters.nazwa_firmy;
        const matchesOsobaPrywatna = filters.nazwisko === '' || rezerwacja.osobaPrywatnaId === filters.nazwisko;
        return matchesAuto && (matchesFirma || matchesOsobaPrywatna);
    });

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>
                Rezerwacje
            </Typography>
            <Box className="button-group">
                <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                    Dodaj Rezerwację
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setFiltersVisible(!filtersVisible)}
                >
                    Filtruj
                </Button>
            </Box>

            {/* Filter */}
            {filtersVisible && (
                <Box className="filter-box">
                    <FormControl fullWidth>
                        <TextField
                            select
                            name="autoId"
                            label="Auto"
                            value={form.autoId || ''}  // Zapewniamy, że pole jest kontrolowane
                            onChange={handleInputChange}
                            fullWidth
                        >
                            {flota.map((pojazd) => (
                                <option key={pojazd.id} value={pojazd.id}>
                                    {pojazd.marka} - {pojazd.model}
                                </option>
                            ))}
                        </TextField>
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            label="Firma"
                            name="nazwa_firmy"
                            onChange={handleInputChange}
                            value={filters.nazwa_firmy}
                            placeholder="Nazwa firmy"
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            label="Osoba Prywatna"
                            variant="outlined"
                            margin="normal"
                            name="nazwisko"
                            onChange={handleFilterChange}
                            value={filters.nazwisko}
                            placeholder="Nazwisko"
                        />
                    </FormControl>
                </Box>
            )}

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{form.id ? 'Edytuj Rezerwację' : 'Dodaj Rezerwację'}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit}>
                        {/* Od Kiedy */}
                        <TextField
                            name="odKiedy"
                            label="Od Kiedy"
                            type="date"
                            value={form.odKiedy || ''}
                            onChange={handleInputChange}
                            required
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />

                        {/* Do Kiedy */}
                        <TextField
                            name="doKiedy"
                            label="Do Kiedy"
                            type="date"
                            value={form.doKiedy || ''}
                            onChange={handleInputChange}
                            required
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />

                        {/* Auto */}
                        <FormControl fullWidth required>
                            <TextField
                                select
                                name="autoId"
                                label="Auto"
                                value={form.autoId || ''}
                                onChange={handleInputChange}
                                fullWidth
                            >
                                {flota.map((pojazd) => (
                                    <MenuItem key={pojazd.id} value={pojazd.id}>
                                        {pojazd.marka} - {pojazd.model}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>

                        <FormControl fullWidth required>
                            <TextField
                                select
                                name="firmaId"
                                label="Firma"
                                value={form.firmaId || null}
                                onChange={handleInputChange}
                                fullWidth
                            >
                                {firmy.map((firma) => (
                                    <MenuItem key={firma.id} value={firma.id}>
                                        {firma.nazwa_firmy}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>

                        <FormControl fullWidth required>
                            <TextField
                                select
                                name="osobaPrywatnaId"
                                label="Osoba Prywatna"
                                value={form.osobaPrywatnaId || null}
                                onChange={handleInputChange}
                                fullWidth
                            >
                                {osobyPrywatne.map((osoba) => (
                                    <MenuItem key={osoba.id} value={osoba.id}>
                                        {osoba.imie} {osoba.nazwisko}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>

                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        {form.id ? 'Zapisz' : 'Dodaj'}
                    </Button>
                </DialogActions>
            </Dialog>




            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Od Kiedy</TableCell>
                            <TableCell>Do Kiedy</TableCell>
                            <TableCell>Auto</TableCell>
                            <TableCell>Firma</TableCell>
                            <TableCell>Osoba Prywatna</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRezerwacje.map((rezerwacja) => (
                            <TableRow key={rezerwacja.id}>
                                <TableCell>{rezerwacja.odKiedy}</TableCell>
                                <TableCell>{rezerwacja.doKiedy}</TableCell>

                                {/* Display the auto details */}
                                <TableCell>
                                    {flota.find((auto) => auto.id === rezerwacja.auto_id)?.marka || 'Brak'}
                                    {' - '}
                                    {flota.find((auto) => auto.id === rezerwacja.auto_id)?.model || 'Brak'}
                                </TableCell>

                                {/* Display the firma details */}
                                <TableCell>
                                    {firmy.find((firma) => firma.id === rezerwacja.firma_id)?.nazwa_firmy || 'Brak'}
                                </TableCell>

                                <TableCell>
                                    {osobyPrywatne.find((osoba) => osoba.id === rezerwacja.osoba_prywatna_id)?.imie + ' ' +
                                        osobyPrywatne.find((osoba) => osoba.id === rezerwacja.osoba_prywatna_id)?.nazwisko || 'Brak'}
                                </TableCell>

                                <TableCell>
                                    <Button onClick={() => handleEditClick(rezerwacja)} color="primary">
                                        Edytuj
                                    </Button>
                                    <Button onClick={() => handleDeleteClick(rezerwacja.id)} color="secondary">
                                        Usuń
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ConfirmationDialog />
        </Box>
    );
};

export default Rezerwacje;
