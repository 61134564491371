import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { saveAs } from 'file-saver';

const ArchiwalneUmowyPriv = ({ archiwalneUmowyPriv }) => {
    const handleDownload = (file, fileName) => {
        saveAs(file, fileName || 'Archiwalna_umowa.docx');
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nazwa pliku</TableCell>
                        <TableCell>Data Archiwizacji</TableCell>
                        <TableCell>Klient</TableCell>
                        <TableCell>Akcje</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {archiwalneUmowyPriv.map((umowa) => (
                        <TableRow key={umowa.id}>
                            <TableCell>{umowa.fileName || 'Umowa_bez_nazwy.docx'}</TableCell>
                            <TableCell>{umowa.dateArchived}</TableCell>
                            <TableCell>{umowa.clientName || 'Nieznany'}</TableCell>
                            <TableCell>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleDownload(umowa.file, umowa.fileName)}
                                >
                                    Pobierz
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ArchiwalneUmowyPriv;
