import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Grid,
    MenuItem,
    Collapse
} from '@mui/material';

const HistoriaSerwisowa = ({ flota = [], historiaSerwisowa = [], onAdd, onDelete }) => {
    const [form, setForm] = useState({
        pojazdId: '',
        data: '',
        opis: '',
    });
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterOptions, setFilterOptions] = useState({
        pojazdId: '',
        dataFrom: '',
        dataTo: '',
        opis: ''
    });
    const [filtersVisible, setFiltersVisible] = useState(false); // To toggle the filter panel visibility

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    // Handle filter input changes
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilterOptions({ ...filterOptions, [name]: value });
    };

    // Handle form submission for adding history entry
    const handleFormSubmit = (e) => {
        e.preventDefault();
        onAdd({ ...form, id: Date.now() });
        resetForm();
    };

    // Reset form after submission
    const resetForm = () => {
        setForm({ pojazdId: '', data: '', opis: '' });
        setOpen(false);
    };

    // Handle delete button click
    const handleDeleteClick = (id) => {
        onDelete(id);
    };

    // Filter history service entries based on filters
    const filteredHistoriaSerwisowa = historiaSerwisowa.filter((entry) => {
        const pojazd = flota.find((p) => p.id === entry.pojazdId);

        const matchesSearchTerm = pojazd
            ? pojazd.model.toLowerCase().includes(searchTerm.toLowerCase()) ||
              pojazd.marka.toLowerCase().includes(searchTerm.toLowerCase()) ||
              entry.opis.toLowerCase().includes(searchTerm.toLowerCase())
            : false;

        const matchesFilters =
            (filterOptions.pojazdId ? entry.pojazdId === filterOptions.pojazdId : true) &&
            (filterOptions.dataFrom ? entry.data >= filterOptions.dataFrom : true) &&
            (filterOptions.dataTo ? entry.data <= filterOptions.dataTo : true) &&
            (filterOptions.opis ? entry.opis.toLowerCase().includes(filterOptions.opis.toLowerCase()) : true);

        return matchesSearchTerm && matchesFilters;
    });

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Historia Serwisowa
            </Typography>

            {/* Filter Toggle Button */}
            <Box display="flex" justifyContent="flex-start" marginBottom={2}>
                <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                    Dodaj wpis
                </Button>
                <Button variant="outlined" onClick={() => setFiltersVisible(!filtersVisible)}>
                    {filtersVisible ? 'Zwiń filtry' : 'Pokaż filtry'}
                </Button>
            </Box>

            {/* Filter Panel (Collapsible) */}
            <Collapse in={filtersVisible}>
                <Box padding={2} border={1} borderColor="grey.300" marginBottom={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                select
                                label="Wybierz pojazd"
                                name="pojazdId"
                                value={filterOptions.pojazdId}
                                onChange={handleFilterChange}
                                fullWidth
                            >
                                <MenuItem value="">Wszystkie</MenuItem>
                                {flota.map((pojazd) => (
                                    <MenuItem key={pojazd.id} value={pojazd.id}>
                                        {pojazd.model} - {pojazd.marka}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Data od"
                                type="date"
                                name="dataFrom"
                                value={filterOptions.dataFrom}
                                onChange={handleFilterChange}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Data do"
                                type="date"
                                name="dataTo"
                                value={filterOptions.dataTo}
                                onChange={handleFilterChange}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Opis"
                                name="opis"
                                value={filterOptions.opis}
                                onChange={handleFilterChange}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Collapse>

            {/* Search Input */}
            <TextField
                label="Wyszukaj"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
                margin="normal"
            />

            {/* Historia Serwisowa Table */}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Model</TableCell>
                            <TableCell>Marka</TableCell>
                            <TableCell>Data</TableCell>
                            <TableCell>Opis</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredHistoriaSerwisowa.map((entry) => {
                            const pojazd = flota.find((p) => p.id === entry.pojazdId);
                            return (
                                <TableRow key={entry.id}>
                                    <TableCell>{pojazd?.model || 'Nieznany'}</TableCell>
                                    <TableCell>{pojazd?.marka || 'Nieznany'}</TableCell>
                                    <TableCell>{entry.data}</TableCell>
                                    <TableCell>{entry.opis}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleDeleteClick(entry.id)}
                                        >
                                            Usuń
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Dialog for Adding New History Entry */}
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>Dodaj Wpis Historii Serwisowej</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    name="pojazdId"
                                    label="Wybierz pojazd"
                                    value={form.pojazdId}
                                    onChange={handleInputChange}
                                    required
                                    fullWidth
                                >
                                    {flota.map((pojazd) => (
                                        <MenuItem key={pojazd.id} value={pojazd.id}>
                                            {pojazd.model} - {pojazd.marka}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="data"
                                    label="Data"
                                    type="date"
                                    value={form.data}
                                    onChange={handleInputChange}
                                    required
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="opis"
                                    label="Opis"
                                    value={form.opis}
                                    onChange={handleInputChange}
                                    required
                                    multiline
                                    rows={4}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        Dodaj
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default HistoriaSerwisowa;
