import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { useConfirmDelete } from './ConfirmDelete';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';

const UmowaNajmuFirma = () => {
    const [form, setForm] = useState({
        nazwa: '',
        data: '',
        godzina: '',
        dzien: '',
        dogodziny: '',
        dodzien: '',
        dodatkowe: '',
        selectedVehicle: '',
        selectedFirma: '', // Zmieniamy na selectedFirma
    });
    const [documents, setDocuments] = useState([]);
    const [flota, setFlota] = useState([]);
    const [firmy, setFirmy] = useState([]);  // Dodajemy stan dla firm
    const [open, setOpen] = useState(false);
    const { confirmDelete, ConfirmationDialog } = useConfirmDelete();

    // Pobieranie dokumentów przy załadowaniu komponentu
    useEffect(() => {
        fetch('https://autosolgasystem.pl/django-app/dbview/dokumenty/')
            .then((response) => response.json())
            .then((data) => {
                const filteredDocuments = data.filter((doc) => doc.rodzaj === 'umowa najmu firma');
                setDocuments(filteredDocuments);
            })
            .catch((error) => console.error('Błąd pobierania dokumentów:', error));

        // Pobieranie floty pojazdów
        fetch('https://autosolgasystem.pl/django-app/dbview/')
            .then((response) => response.json())
            .then((data) => setFlota(data))
            .catch((error) => console.error('Błąd pobierania floty:', error));

        // Pobieranie firm
        fetch('https://autosolgasystem.pl/django-app/dbview/firma_disp') // Używamy endpointu dla firm
            .then((response) => response.json())
            .then((data) => setFirmy(data))
            .catch((error) => console.error('Błąd pobierania firm:', error));
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const generateDocx = async () => {
        const templateUrl = '/UmowaNajmuFirma.docx'; // Ensure template is correctly provided
        const response = await fetch(templateUrl);

        if (!response.ok) {
            console.error('Błąd pobierania szablonu:', response.statusText);
            return;
        }

        const blob = await response.blob();
        const arrayBuffer = await blob.arrayBuffer();
        const zip = new PizZip(arrayBuffer);
        const doc = new Docxtemplater(zip, { paragraphLoop: true, lineBreaks: true });

        const selectedVehicle = flota.find((v) => v.id === form.selectedVehicle) || {};
        const selectedFirma = firmy.find((f) => f.id === form.selectedFirma) || {}; // Pobieramy firmę

        doc.setData({
            nazwa: form.nazwa,
            data: form.data,
            godzina: form.godzina,
            dzien: form.dzien,
            dogodziny: form.dogodziny,
            dodzien: form.dodzien,
            dodatkowe: form.dodatkowe,
            marka: selectedVehicle.marka,
            model: selectedVehicle.model,
            registrationNumber: selectedVehicle.registrationNumber,
            firmaNazwa: selectedFirma.nazwa_firmy, // Ustawiamy dane firmy
            firmaNip: selectedFirma.nip, // Dodajemy NIP firmy do danych
            currentDate: new Date().toLocaleDateString(),
        });

        try {
            doc.render();
            const out = doc.getZip().generate({ type: 'blob' });

            // Save the document as a file
            saveAs(out, `${form.nazwa}.docx`);

            // Optional: send document data to the backend or update state
            const newDocument = {
                id: Date.now(),
                nazwa: form.nazwa,
                data: form.data,
                plik: out,
                status: 'umowa najmu firma',
                rodzaj: 'umowa najmu firma',
            };

            setDocuments((prevDocs) => [...prevDocs, newDocument]);

        } catch (error) {
            console.error('Błąd podczas generowania pliku:', error);
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        generateDocx();
        resetForm();
    };

    const handleDeleteClick = async (id) => {
        const confirmed = await confirmDelete('Czy na pewno chcesz usunąć ten dokument?');
        if (confirmed) {
            setDocuments((prevDocs) => prevDocs.filter((doc) => doc.id !== id));
        }
    };

    const resetForm = () => {
        setForm({
            nazwa: '',
            data: '',
            godzina: '',
            dzien: '',
            dogodziny: '',
            dodzien: '',
            dodatkowe: '',
            selectedVehicle: '',
            selectedFirma: '', // Resetujemy dane firmy
        });
        setOpen(false);
    };

    return (
        <Box>
            <h2>Dokumenty - umowa najmu firma</h2>
            <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                Dodaj Dokument
            </Button>

            {/* Formularz dodawania */}
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Dodaj Dokument</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit}>
                        <TextField
                            name="nazwa"
                            label="Nazwa Dokumentu"
                            value={form.nazwa}
                            onChange={handleInputChange}
                            required
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            name="data"
                            label="Data"
                            type="date"
                            value={form.data}
                            onChange={handleInputChange}
                            required
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            name="godzina"
                            label="Godzina"
                            value={form.godzina}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            name="dzien"
                            label="Dzień"
                            value={form.dzien}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            name="dogodziny"
                            label="Do Godziny"
                            value={form.dogodziny}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            name="dodzien"
                            label="Do Dnia"
                            value={form.dodzien}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            name="dodatkowe"
                            label="Dodatkowe Informacje"
                            value={form.dodatkowe}
                            onChange={handleInputChange}
                            fullWidth
                            multiline
                            margin="normal"
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Wybierz pojazd</InputLabel>
                            <Select
                                name="selectedVehicle"
                                value={form.selectedVehicle}
                                onChange={handleInputChange}
                                required
                            >
                                <MenuItem value=""><em>-- Wybierz pojazd --</em></MenuItem>
                                {flota.map((vehicle) => (
                                    <MenuItem key={vehicle.id} value={vehicle.id}>
                                        {vehicle.marka} - {vehicle.model}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <InputLabel>Wybierz firmę</InputLabel>
                            <Select
                                name="selectedFirma"
                                value={form.selectedFirma}
                                onChange={handleInputChange}
                                required
                            >
                                <MenuItem value=""><em>-- Wybierz firmę --</em></MenuItem>
                                {firmy.map((firma) => (
                                    <MenuItem key={firma.id} value={firma.id}>
                                        {firma.nazwa_firmy}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        Zapisz
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Tabela z dokumentami */}
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nazwa</TableCell>
                            <TableCell>Data</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Plik</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documents.map((doc) => (
                            <TableRow key={doc.id}>
                                <TableCell>{doc.nazwa}</TableCell>
                                <TableCell>{doc.data}</TableCell>
                                <TableCell>{doc.status}</TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() =>
                                            window.open(`https://autosolgasystem.pl/media/${doc.plik}`, '_blank')
                                        }
                                        color="primary"
                                    >
                                        Pobierz
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button onClick={() => handleDeleteClick(doc.id)} color="secondary">
                                        Usuń
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <ConfirmationDialog />
        </Box>
    );
};

export default UmowaNajmuFirma;
