// Ustawienia.js
import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';

const Ustawienia = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleChangePassword = () => {
        if (newPassword !== confirmPassword) {
            setMessage('Nowe hasła muszą być takie same.');
            return;
        }
        // Logika do zmiany hasła (np. wysłanie do API)
        // resetuj pola po udanej zmianie
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setMessage('Hasło zostało zmienione pomyślnie!');
    };

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4">Ustawienia</Typography>
            <TextField
                label="Stare hasło"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
            />
            <TextField
                label="Nowe hasło"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
                label="Potwierdź nowe hasło"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {message && <Typography color="error">{message}</Typography>}
            <Button variant="contained" onClick={handleChangePassword}>
                Zmień hasło
            </Button>
        </Box>
    );
};

export default Ustawienia;
