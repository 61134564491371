import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';

const WydanieOdbiorSamochodu = ({ flota, kontrahenci }) => {
    const [form, setForm] = useState({
        rentalAmount: '',
        mileage: '',
        fuelLevel: '',
        vehicleCondition: '',
        spareTire: false,
        firstAidKit: false,
        selectedVehicle: '',
        selectedClient: '',
    });
    const [documents, setDocuments] = useState([]);
    const [open, setOpen] = useState(false);
    const [operationType, setOperationType] = useState(''); // 'wydanie' lub 'odbior'

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setForm({ ...form, [name]: type === 'checkbox' ? checked : value });
    };

    const handleOpenDialog = (type) => {
        setOperationType(type);
        setOpen(true);
    };

    const generateDocx = async () => {
        const templateUrl = operationType === 'wydanie' ? '/Protokol-odbioru.docx' : '/Protokol-wydania.docx';
        const response = await fetch(templateUrl);

        if (!response.ok) {
            console.error('Błąd podczas pobierania szablonu:', response.statusText);
            return;
        }

        const blob = await response.blob();
        const arrayBuffer = await blob.arrayBuffer();

        const zip = new PizZip(arrayBuffer);
        const doc = new Docxtemplater(zip, { paragraphLoop: true, lineBreaks: true });

        const selectedVehicle = flota.find(v => v.id === form.selectedVehicle) || {};
        const selectedClient = kontrahenci.find(c => c.id === form.selectedClient) || {};

        doc.setData({
            rentalAmount: form.rentalAmount || '',
            mileage: form.mileage || '',
            marka: selectedVehicle.marka || '',
            model: selectedVehicle.model || '',
            registrationNumber: selectedVehicle.registrationNumber || '',
            imie: form.imie || '',
            nazwisko: form.nazwisko || '',
            nrDowodu: selectedClient.nrDowodu || '',
            pesel: selectedClient.pesel || '',
            telefon: selectedClient.telefon || '',
            adres: selectedClient.adres || '',
            currentDate: new Date().toLocaleDateString(),
        });

        try {
            doc.render();
            const out = doc.getZip().generate({ type: "blob" });

            const document = {
                id: Date.now(),
                operationType,
                dateGenerated: new Date().toLocaleString(),
                file: out,
            };
            setDocuments([...documents, document]);
        } catch (error) {
            console.error('Błąd podczas generowania pliku:', error);
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        generateDocx();
        setOpen(false);
    };

    const handleDownload = (file, type) => {
        const fileName = type === 'wydanie' ? 'Protokół_wydania' : 'Protokół_odbioru';
        saveAs(file, `${fileName}_${new Date().toISOString()}.docx`);
    };

    return (
        <Box>
            <Button variant="contained" onClick={() => handleOpenDialog('wydanie')}>Wydaj Samochód</Button>
            <Button variant="outlined" sx={{ ml: 2 }} onClick={() => handleOpenDialog('odbior')}>Odbierz Samochód</Button>
            
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{operationType === 'wydanie' ? 'Protokół Wydania' : 'Protokół Odbioru'}</DialogTitle>
                <DialogContent>
                <TextField
                        name="Kontrahent"
                        label="Kontrahent"
                        value={form.imie}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        name="Kontrahent2"
                        label="Kontrahent2"
                        value={form.nazwisko}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        name="rentalAmount"
                        label="Kwota wynajmu"
                        value={form.rentalAmount}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        name="mileage"
                        label="Stan licznika"
                        value={form.mileage}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        name="fuelLevel"
                        label="Stan paliwa"
                        value={form.fuelLevel}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        name="vehicleCondition"
                        label="Stan pojazdu"
                        value={form.vehicleCondition}
                        onChange={handleInputChange}
                        fullWidth
                        multiline
                    />
                    <FormControlLabel
                        control={<Checkbox checked={form.spareTire} onChange={handleInputChange} name="spareTire" />}
                        label="Koło zapasowe"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={form.firstAidKit} onChange={handleInputChange} name="firstAidKit" />}
                        label="Apteczka"
                    />
                    <FormControl fullWidth>
                        <InputLabel>Wybierz pojazd</InputLabel>
                        <Select
                            name="selectedVehicle"
                            value={form.selectedVehicle}
                            onChange={handleInputChange}
                        >
                            <MenuItem value=""><em>-- Wybierz pojazd --</em></MenuItem>
                            {flota.map(vehicle => (
                                <MenuItem key={vehicle.id} value={vehicle.id}>
                                    {vehicle.model} - {vehicle.vin}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Wybierz kontrahenta</InputLabel>
                        <Select
                            name="selectedClient"
                            value={form.selectedClient}
                            onChange={handleInputChange}
                        >
                            <MenuItem value=""><em>-- Wybierz kontrahenta --</em></MenuItem>
                            {kontrahenci.map(client => (
                                <MenuItem key={client.id} value={client.id}>
                                    {client.nazwisko} - {client.pesel}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Anuluj</Button>
                    <Button onClick={handleFormSubmit}>Generuj Dokument</Button>
                </DialogActions>
            </Dialog>

            {documents.length > 0 && (
                <TableContainer component={Paper} sx={{ mt: 3 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Data wygenerowania</TableCell>
                                <TableCell>Typ Operacji</TableCell>
                                <TableCell>Akcje</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {documents.map(doc => (
                                <TableRow key={doc.id}>
                                    <TableCell>{doc.dateGenerated}</TableCell>
                                    <TableCell>{doc.operationType === 'wydanie' ? 'Wydanie' : 'Odbiór'}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleDownload(doc.file, doc.operationType)}>Pobierz</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

export default WydanieOdbiorSamochodu;
