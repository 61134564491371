import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    useMediaQuery,
    useTheme,
    Typography,
    Grid
} from '@mui/material';
import './styles.css'; // Import the CSS file
import { useConfirmDelete } from './ConfirmDelete'; // Import the hook for confirmation

const Kontrahent = ({ kontrahenci, onAdd, onDelete, onEdit }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [form, setForm] = useState({
        imie: '',
        nazwisko: '',
        pesel: '',
        nip: '',
        adres: '',
        kodPocztowy: '',
        nrDowodu: '',
        email: '',
        telefon: ''
    });
    const [open, setOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editId, setEditId] = useState(null);
    
    const [filters, setFilters] = useState({
        imie: '',
        nazwisko: '',
        pesel: '',
        nip: '',
        adres: '',
        kodPocztowy: '',
        nrDowodu: '',
        email: '',
        telefon: ''
    });
    const [filtersVisible, setFiltersVisible] = useState(false); // Sterowanie widocznością filtrów

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (isEditMode) {
            onEdit({ ...form, id: editId });
        } else {
            onAdd({ ...form, id: Date.now() });
        }
        setForm({ imie: '', nazwisko: '', pesel: '', nip: '', adres: '', kodPocztowy: '', nrDowodu: '', email: '', telefon: '' });
        setOpen(false);
        setIsEditMode(false);
        setEditId(null);
    };

    const handleDeleteClick = async (id) => {
        const confirmed = await confirmDelete('Czy na pewno chcesz usunąć ten rekord?');
        if (confirmed) {
            onDelete(id);
        }
    };

    const handleEditClick = (kontrahent) => {
        setForm(kontrahent);
        setEditId(kontrahent.id);
        setIsEditMode(true);
        setOpen(true);
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value.toLowerCase() });
    };

    const filteredKontrahenci = kontrahenci.filter((kontrahent) => {
        return (
            kontrahent.imie.toLowerCase().includes(filters.imie) &&
            kontrahent.nazwisko.toLowerCase().includes(filters.nazwisko) &&
            kontrahent.pesel.toLowerCase().includes(filters.pesel) &&
            kontrahent.nip.toLowerCase().includes(filters.nip) &&
            kontrahent.adres.toLowerCase().includes(filters.adres) &&
            kontrahent.kodPocztowy.toLowerCase().includes(filters.kodPocztowy) &&
            kontrahent.nrDowodu.toLowerCase().includes(filters.nrDowodu) &&
            kontrahent.email.toLowerCase().includes(filters.email) &&
            kontrahent.telefon.toLowerCase().includes(filters.telefon)
        );
    });

    const { confirmDelete, ConfirmationDialog } = useConfirmDelete();

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>
                Kontrahent
            </Typography>
            <Box className="button-group">
                <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                    Dodaj Kontrahenta
                </Button>
                <Button 
                    variant="outlined" 
                    color="secondary" 
                    onClick={() => setFiltersVisible(!filtersVisible)}
                >
                    Filtruj
                </Button>
            </Box>

            {/* Sekcja filtrów - ukrywanie/pokazywanie po kliknięciu */}
            <Box className={`filter-container ${filtersVisible ? 'show' : ''}`}>
                <TextField
                    label="Imię"
                    variant="outlined"
                    margin="normal"
                    name="imie"
                    onChange={handleFilterChange}
                    value={filters.imie}
                    className="filter-field"
                    placeholder="Imię"
                />
                <TextField
                    label="Nazwisko"
                    variant="outlined"
                    margin="normal"
                    name="nazwisko"
                    onChange={handleFilterChange}
                    value={filters.nazwisko}
                    className="filter-field"
                    placeholder="Nazwisko"
                />
                <TextField
                    label="PESEL"
                    variant="outlined"
                    margin="normal"
                    name="pesel"
                    onChange={handleFilterChange}
                    value={filters.pesel}
                    className="filter-field"
                    placeholder="PESEL"
                />
                <TextField
                    label="NIP"
                    variant="outlined"
                    margin="normal"
                    name="nip"
                    onChange={handleFilterChange}
                    value={filters.nip}
                    className="filter-field"
                    placeholder="NIP"
                />
                <TextField
                    label="Adres"
                    variant="outlined"
                    margin="normal"
                    name="adres"
                    onChange={handleFilterChange}
                    value={filters.adres}
                    className="filter-field"
                    placeholder="Adres"
                />
                <TextField
                    label="Kod Pocztowy"
                    variant="outlined"
                    margin="normal"
                    name="kodPocztowy"
                    onChange={handleFilterChange}
                    value={filters.kodPocztowy}
                    className="filter-field"
                    placeholder="Kod Pocztowy"
                />
                <TextField
                    label="Nr Dowodu/Paszportu"
                    variant="outlined"
                    margin="normal"
                    name="nrDowodu"
                    onChange={handleFilterChange}
                    value={filters.nrDowodu}
                    className="filter-field"
                    placeholder="Nr Dowodu/Paszportu"
                />
                <TextField
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    name="email"
                    onChange={handleFilterChange}
                    value={filters.email}
                    className="filter-field"
                    placeholder="Email"
                />
                <TextField
                    label="Telefon"
                    variant="outlined"
                    margin="normal"
                    name="telefon"
                    onChange={handleFilterChange}
                    value={filters.telefon}
                    className="filter-field"
                    placeholder="Telefon"
                />
            </Box>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{isEditMode ? 'Edytuj Kontrahenta' : 'Dodaj Kontrahenta'}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit} className="form-container">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="imie"
                                    label="Imię"
                                    value={form.imie}
                                    onChange={handleInputChange}
                                    required
                                    className="textfield"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="nazwisko"
                                    label="Nazwisko"
                                    value={form.nazwisko}
                                    onChange={handleInputChange}
                                    required
                                    className="textfield"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="pesel"
                                    label="PESEL"
                                    value={form.pesel}
                                    onChange={handleInputChange}
                                    required
                                    className="textfield"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="nip"
                                    label="NIP"
                                    value={form.nip}
                                    onChange={handleInputChange}
                                    required
                                    className="textfield"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="adres"
                                    label="Adres zamieszkania"
                                    value={form.adres}
                                    onChange={handleInputChange}
                                    className="textfield"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="kodPocztowy"
                                    label="Kod pocztowy"
                                    value={form.kodPocztowy}
                                    onChange={handleInputChange}
                                    className="textfield"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="nrDowodu"
                                    label="Numer dowodu"
                                    value={form.nrDowodu}
                                    onChange={handleInputChange}
                                    className="textfield"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="email"
                                    label="Email"
                                    value={form.email}
                                    onChange={handleInputChange}
                                    required
                                    className="textfield"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="telefon"
                                    label="Numer telefonu"
                                    value={form.telefon}
                                    onChange={handleInputChange}
                                    className="textfield"
                                />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Anuluj
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        {isEditMode ? 'Zapisz' : 'Dodaj'}
                    </Button>
                </DialogActions>
            </Dialog>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Imię</TableCell>
                            <TableCell>Nazwisko</TableCell>
                            <TableCell>PESEL</TableCell>
                            <TableCell>NIP</TableCell>
                            <TableCell>Adres</TableCell>
                            <TableCell>Kod Pocztowy</TableCell>
                            <TableCell>Nr Dowodu</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Telefon</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredKontrahenci.map((kontrahent) => (
                            <TableRow key={kontrahent.id}>
                                <TableCell>{kontrahent.imie}</TableCell>
                                <TableCell>{kontrahent.nazwisko}</TableCell>
                                <TableCell>{kontrahent.pesel}</TableCell>
                                <TableCell>{kontrahent.nip}</TableCell>
                                <TableCell>{kontrahent.adres}</TableCell>
                                <TableCell>{kontrahent.kodPocztowy}</TableCell>
                                <TableCell>{kontrahent.nrDowodu}</TableCell>
                                <TableCell>{kontrahent.email}</TableCell>
                                <TableCell>{kontrahent.telefon}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEditClick(kontrahent)}>Edytuj</Button>
                                    <Button onClick={() => handleDeleteClick(kontrahent.id)}>Usuń</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ConfirmationDialog />
        </Box>
    );
};

export default Kontrahent;
