import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    Grid,
    Collapse,
} from '@mui/material';
import './styles.css';
import { useConfirmDelete } from './ConfirmDelete';

const Mandaty = () => {
    const [form, setForm] = useState({
        id: '',
        numer: '',
        data: '',
        kwota: '',
        metoda_platnosci: '',
        osobaPrywatnaId: '',
    });
    const [open, setOpen] = useState(false);
    const [mandaty, setMandaty] = useState([]);
    const [osoby, setOsoby] = useState([]);
    const [filters, setFilters] = useState({
        numer: '',
        osobaPrywatnaId: '',
    });
    const [openFilters, setOpenFilters] = useState(false);  // State for managing filter visibility
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [selectedFineId, setSelectedFineId] = useState(null);

    useEffect(() => {
        fetch('https://autosolgasystem.pl/django-app/dbview/mandat_disp')
            .then((response) => response.json())
            .then((data) => setMandaty(data))
            .catch((error) => console.error('Error fetching mandaty:', error));

        fetch('https://autosolgasystem.pl/django-app/dbview/osoba_disp')
            .then((response) => response.json())
            .then((data) => setOsoby(data))
            .catch((error) => console.error('Error fetching osoby:', error));
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const payload = {
            numer: form.numer,
            data: form.data,
            kwota: form.kwota,
            metoda_platnosci: form.metoda_platnosci,
            osobaPrywatnaId: form.osobaPrywatnaId,
        };

        if (form.id) {
            // Update existing mandate
            fetch(`https://autosolgasystem.pl/django-app/dbview/update_mandat/${form.id}/`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    setMandaty((prevMandaty) =>
                        prevMandaty.map((mandat) =>
                            mandat.id === form.id ? { ...mandat, ...data } : mandat
                        )
                    );
                    resetForm();
                })
                .catch((error) => console.error('Error updating mandate:', error));
        } else {
            // Create new mandate
            fetch('https://autosolgasystem.pl/django-app/dbview/create_mandat', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    setMandaty((prevMandaty) => [...prevMandaty, data]);
                    resetForm();
                })
                .catch((error) => console.error('Error creating mandate:', error));
        }
    };

    const resetForm = () => {
        setForm({
            numer: '',
            data: '',
            kwota: '',
            metoda_platnosci: '',
            osobaPrywatnaId: '',
        });
        setOpen(false);
    };

    const handleDeleteClick = (id) => {
        setSelectedFineId(id);
        setOpenConfirmDialog(true);
    };

    const handleConfirmDelete = () => {
        if (selectedFineId !== null) {
            fetch(`https://autosolgasystem.pl/django-app/dbview/delete_mandat/${selectedFineId}/`, {
                method: 'DELETE',
            })
                .then((response) => response.json())
                .then(() => {
                    setMandaty((prevMandaty) =>
                        prevMandaty.filter((mandat) => mandat.id !== selectedFineId)
                    );
                })
                .catch((error) => console.error('Error deleting mandate:', error));
        }
        setOpenConfirmDialog(false);
    };

    const handleCancelDelete = () => {
        setOpenConfirmDialog(false);
    };

    const handleEditClick = (mandat) => {
        setForm(mandat);
        setOpen(true);
    };

    const filteredMandaty = mandaty.filter((mandat) => {
        const matchesNumer =
            filters.numer === '' || mandat.numer.toLowerCase().includes(filters.numer.toLowerCase());
        const matchesOsoba =
            filters.osobaPrywatnaId === '' || mandat.osobaPrywatnaId === filters.osobaPrywatnaId;
        return matchesNumer && matchesOsoba;
    });

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Mandaty
            </Typography>
            <Box className="button-group">
                <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                    Dodaj Mandat
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setOpenFilters(!openFilters)}  // Toggle filters visibility
                >
                    Filtruj
                </Button>
            </Box>

            {/* Filter */}
            <Collapse in={openFilters} timeout="auto" unmountOnExit>
                <Box className="filter-box" sx={{ marginBottom: 2, marginTop: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Numer Mandatu"
                                name="numerMandatu"
                                value={filters.numer}
                                onChange={handleFilterChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel>Osoba Prywatna</InputLabel>
                                <Select
                                    name="osobaPrywatnaId"
                                    value={filters.osobaPrywatnaId}
                                    onChange={handleFilterChange}
                                    fullWidth
                                >
                                    <MenuItem value="">Wszystkie</MenuItem>
                                    {osoby.map((osoba) => (
                                        <MenuItem key={osoba.id} value={osoba.id}>
                                            {osoba.imie} {osoba.nazwisko}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Collapse>

            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Numer Mandatu</TableCell>
                            <TableCell>Data</TableCell>
                            <TableCell>Kwota</TableCell>
                            <TableCell>Metoda Płatności</TableCell>
                            <TableCell>Osoba</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredMandaty.map((mandat) => {
                            const osoba = osoby.find((o) => o.id === mandat.osobaPrywatnaId);
                            return (
                                <TableRow key={mandat.id}>
                                    <TableCell>{mandat.numer}</TableCell>
                                    <TableCell>{mandat.data}</TableCell>
                                    <TableCell>{mandat.kwota}</TableCell>
                                    <TableCell>
                                        {mandat.metoda_platnosci === 'card'
                                            ? 'Karta'
                                            : mandat.metoda_platnosci === 'cash'
                                                ? 'Gotówka'
                                                : 'Przelew'}
                                    </TableCell>
                                    <TableCell>{osoba?.imie} {osoba?.nazwisko || 'Brak'}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleEditClick(mandat)} color="primary">
                                            Edytuj
                                        </Button>
                                        <Button onClick={() => handleDeleteClick(mandat.id)} color="secondary">
                                            Usuń
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>{form.id ? 'Edytuj Mandat' : 'Dodaj Mandat'}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="numer"
                                    label="Numer Mandatu"
                                    value={form.numer}
                                    onChange={handleInputChange}
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="data"
                                    label="Data"
                                    type="date"
                                    value={form.data}
                                    onChange={handleInputChange}
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="kwota"
                                    label="Kwota"
                                    type="number"
                                    value={form.kwota}
                                    onChange={handleInputChange}
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel>Metoda Płatności</InputLabel>
                                    <Select
                                        name="metoda_platnosci"
                                        value={form.metoda_platnosci}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <MenuItem value="card">Karta</MenuItem>
                                        <MenuItem value="cash">Gotówka</MenuItem>
                                        <MenuItem value="transfer">Przelew</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth required>
                                    <TextField
                                        select
                                        name="osobaPrywatnaId"
                                        label="Osoba Prywatna"
                                        value={form.osobaPrywatnaId || ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                    >
                                        {osoby.map((osoba) => (
                                            <MenuItem key={osoba.id} value={osoba.id}>
                                                {osoba.imie} {osoba.nazwisko}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        {form.id ? 'Zapisz' : 'Dodaj'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog do potwierdzenia usunięcia mandatu */}
            <Dialog open={openConfirmDialog} onClose={handleCancelDelete}>
                <DialogTitle>Potwierdzenie usunięcia</DialogTitle>
                <DialogContent>
                    <Typography>Jesteś pewny, że chcesz usunąć ten mandat?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary">
                        Usuń
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Mandaty;
